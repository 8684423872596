<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header class="is-flex is-justify-content-space-between is-align-items-center mb-2">
        <h2 class="is-size-6 has-text-danger has-text-weight-bold mr-3">{{ $t('warehouse.remove_item') }}</h2>
        <div @click="$emit('close')" v-if="isModal">
          <b-icon class="cursor-pointer" icon="close-thick"/>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <p>{{ $t('warehouse.remove_item_desc') }}</p>
          <form class="mt-3" @submit="handleSubmit">
            <ValidationProvider :name="$t('warehouse.qty')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('warehouse.qty')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <p class="control w-100">
                  <b-input type="number" id="qty" step="any" v-model.number="form.qty" placeholder="0.0"></b-input>
                </p>
                <p class="control">
                  <b-button type="is-text" class="btn-placeholder">Kg</b-button>
                </p>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('date')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('date')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datetimepicker
                    :placeholder="$t('date')"
                    icon="calendar-text"
                    locale="id-ID"
                    v-model="form.date">
                </b-datetimepicker>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('desc')" rules="max:40" v-slot="{ errors }">
              <b-field :label="$t('desc')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="text" id="description" v-model="form.desc"
                         :placeholder="$t('desc')"></b-input>
              </b-field>
            </ValidationProvider>
          </form>

        </ValidationObserver>
      </section>
      <footer class="has-text-right">
        <b-button type="is-secondary is-light" @click="$emit('close')" v-if="isModal">{{ $t('cancel') }}</b-button>
        <b-button type="is-danger" class="ml-2" @click="handleSubmit">Remove</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "RemoveItem",
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    warehouse: {
      type: Object
    }
  },
  data: () => {
    return {
      form: {}
    }
  },
  methods: {
    handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          this.$loading()

          await this.$store.dispatch('farm/removeItem', {
            warehouse_id: this.warehouse.warehouse_id,
            qty: this.form.qty,
            created_at: this.$timestamp(this.form.date),
            desc: this.form.desc || ''
          })
          this.resetForm()
          this.$store.dispatch('farm/refreshWarehouse')

          this.$loading(false)
          this.$emit('submit')
          this.$emit('close')
        }
      })
    },
    resetForm() {
      this.form = {}
      this.$refs['form'].reset()
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  width: 450px !important;
  max-width: 100%;
}
</style>

